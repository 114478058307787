import s from '../styles/header.module.css';
import React, { useState } from "react";
import { useRouter } from 'next/router';
import Image from 'next/image';
import Link from 'next/link';

// Component version Siteweb : Menu "header" / Chapeau
const OJMenuItemTop = ({itemHref, itemName, itemIsActive}) => {
    const [isHovering, setIsHovered] = useState(false);
    const onMouseEnter = () => setIsHovered(true);
    const onMouseLeave = () => setIsHovered(false);

    return (
      <div className="flex flex-row cursor-pointer" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <Link href={itemHref}>
        {isHovering ? (
            <div className="flex flex-row ">
                <Image src="/menu-noir-G.png" width={10} height={16} className={s.menuImage} alt=""/>            
                <a className={s.menuItemTopHover}>{itemName}</a>
                <Image src="/menu-noir-D.png" width={10} height={16} className={s.menuImage} alt=""/>
            </div>
        ) : (
            itemIsActive ? (
                <div className="flex flex-row">
                    <Image src="/menu-blanc-G.png" width={10} height={16} className={s.menuImageActive} alt=""/>            
                    <a className={s.menuItemTopActive}>{itemName}</a>
                    <Image src="/menu-blanc-D.png" width={10} height={16} className={s.menuImageActive} alt=""/>
                </div>
            ) : (
                <div className="flex flex-row">
                <Image src="/menu-blanc-G.png" width={10} height={16} className={s.menuImage} alt=""/>            
                <a className={s.menuItemTop}>{itemName}</a>
                <Image src="/menu-blanc-D.png" width={10} height={16} className={s.menuImage} alt=""/>
            </div>
            )
        )}
        </Link>
      </div>
    );
  };

// Component version Siteweb : Menu "header" => avec sous-menu
const OJMenuItemTopDropdown = ({itemHref, itemName, itemIsActive, setSMenuActiveCall, children}) => {
    const [isHovering, setIsHovered] = useState(false);
    const onMouseEnter = () => {
        setIsHovered(true);
        setSMenuActiveCall(true);
    }
    const onMouseLeave = () => {
        setIsHovered(false);
        setSMenuActiveCall(false);
    }

    return (
    <div className="flex flex-col cursor-pointer" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <div className="flex flex-row" >
            <Link href={itemHref}>
            {isHovering ? (
                <div className="flex flex-row ">
                    <Image src="/menu-noir-G.png" width={10} height={16} className={s.menuImage} alt=""/>            
                    <a className={s.menuItemTopHover}>{itemName}</a>
                    <Image src="/menu-noir-D.png" width={10} height={16} className={s.menuImage} alt=""/>
                </div>
            ) : (
                itemIsActive ? (
                    <div className="flex flex-row">
                        <Image src="/menu-blanc-G.png" width={10} height={16} className={s.menuImageActive} alt=""/>            
                        <a className={s.menuItemTopActive}>{itemName}</a>
                        <Image src="/menu-blanc-D.png" width={10} height={16} className={s.menuImageActive} alt=""/>
                    </div>
                ) : (
                    <div className="flex flex-row">
                    <Image src="/menu-blanc-G.png" width={10} height={16} className={s.menuImage} alt=""/>            
                    <a className={s.menuItemTop}>{itemName}</a>
                    <Image src="/menu-blanc-D.png" width={10} height={16} className={s.menuImage} alt=""/>
                </div>
                )
            )}
            </Link>
        </div>
        {children}
    </div>
    );
  };
// Component version Siteweb : Sous-Menu item
const OJMenuItemSmenu = ({itemHref, itemName, itemIsActive}) => {
    return (        
        <Link href={itemHref}>
            <a className={itemIsActive ? s.menuItemSMenuActive : s.menuItemSMenu}>{itemName}</a>
        </Link>
    );
};

// Component version Mobile : Menu "header" / Chapeau
const OJMenuBurgerItemTop = ({itemHref, itemName, itemIsActive}) => {
    return (
        <Link href={itemHref}>
            <a className={itemIsActive ? s.menuBurgerItemTopActive : s.menuBurgerItemTop}>{itemName}</a>
        </Link>        
    );
};
// Component version Mobile : Sous-Menu item
const OJMenuBurgerItemSmenu = ({itemHref, itemName, itemIsActive}) => {
    return (
        <Link href={itemHref}>
            <a className={itemIsActive ? s.menuBurgerItemSmenuActive : s.menuBurgerItemSmenu}>{itemName}</a>
        </Link>        
    );
};

export default function Menumain() {

    const router = useRouter();

    const [SMenuJujitsuActive, setSMenuJujitsuActive] = useState(false);
    const SMenuJujitsuHandleHover = () => { setSMenuJujitsuActive(!SMenuJujitsuActive);};
    const [SMenuClubActive, setSMenuClubActive] = useState(false);
    const SMenuClubHandleHover = () => { setSMenuClubActive(!SMenuClubActive);};

    const [MMenuActive, setMMenuActive] = useState(false);
    const buttonMenuBurgerHandleClick = () => { setMMenuActive(!MMenuActive);};
    
    const [SMMenuJujitsuActive, setSMMenuJujitsuActive] = useState(false);
    const buttonSMMenuJujitsuHandleClick = () => { setSMMenuJujitsuActive(!SMMenuJujitsuActive);};
    const [SMMenuClubActive, setSMMenuClubActive] = useState(false);
    const buttonSMMenuClubHandleClick = () => { setSMMenuClubActive(!SMMenuClubActive);};
    return (
    /* Nav pas utile pour le moment */
    <nav>
        {/* MENU version MOBILE */}
        <div className="md:hidden flex flex-col min-w-max">
             {/* Bouton Menu Burger (https://heroicons.com/) */}
             <button 
                onClick={buttonMenuBurgerHandleClick}
                className={`${MMenuActive ? 'rounded-t-md' : 'rounded-md'} self-end p-3 text-ojgreen bg-ojwhite border-ojgreen border-2 hover:bg-ojgreen hover:text-ojwhite hover:border-transparent outline-none`}>
                    <svg className="w-6 h-6 stroke-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
                        <line x1="1" x2="23" y1="5" y2="5"   strokeWidth="2" className={`${MMenuActive ? "-rotate-45 -translate-x-2" : "rotate-0 translate-x-0"} transition-transform duration-500 ease-in-out origin-top-right`}/>
                        <line x1="3" x2="21" y1="12" y2="12" strokeWidth="2" className={`${MMenuActive ? "opacity-0 -translate-x-6" : "opacity-100 translate-x-0"} transition-all duration-500 ease-in-out`} />
                        <line x1="1" x2="23" y1="19" y2="19" strokeWidth="2" className={`${MMenuActive ? "rotate-45 -translate-x-2" : "rotate-0 translate-x-0"} transition-transform duration-500 ease-in-out origin-bottom-right`} />
                    </svg>
            </button> 
            {/* https://youtu.be/qJnIJa-cF2M   --\/ */}            
            <div className={`${s.menuBurgerDivMain} ${MMenuActive ? "translate-x-0 opacity-100 max-h-full" : "translate-x-96 opacity-0 max-h-0"}`}>
                {/* contenu du menu Burger */}
                <ul className="flex flex-col text-center items-center">
                    <li> <OJMenuBurgerItemTop itemHref={"/"} itemName="News" itemIsActive={router.pathname=="/"} /></li>
                    <div className={s.divMBSeparator} />
                    <li>
                        <button type="button" onClick={buttonSMMenuJujitsuHandleClick} className={s.menuBurgerItemTop}>
                            <span className={s.menuBurgerItemTitreAvecSSmenu}>Le Ju-jitsu</span>
                            <svg className={`${router.pathname=="/jujitsu-histoire" || router.pathname=="/jujitsu-codemoral" || router.pathname=="/jujitsu-grades" || router.pathname=="/jujitsu-lexique" 
                                            || SMMenuJujitsuActive ? "rotate-180" : "rotate-0"} ${"w-6 h-6 absolute right-2 transition-transform origin-center duration-500 ease-out"}`} 
                                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" ></path></svg>
                        </button>
                        <ul className={`${router.pathname=="/jujitsu-histoire" || router.pathname=="/jujitsu-codemoral" || router.pathname=="/jujitsu-grades" || router.pathname=="/jujitsu-lexique" 
                                            || SMMenuJujitsuActive ? "max-h-40" : "max-h-0"} flex flex-col overflow-hidden transition-all origin-top duration-500 ease-out`}>
                            <li> <OJMenuBurgerItemSmenu itemHref={"/jujitsu-histoire"} itemName="Histoire" itemIsActive={router.pathname=="/jujitsu-histoire"}/></li>
                            <li> <OJMenuBurgerItemSmenu itemHref={"/jujitsu-codemoral"} itemName="Code moral" itemIsActive={router.pathname=="/jujitsu-codemoral"}/></li>
                            <li> <OJMenuBurgerItemSmenu itemHref={"/jujitsu-grades"} itemName="Les Grades" itemIsActive={router.pathname=="/jujitsu-grades"}/></li>
                            <li> <OJMenuBurgerItemSmenu itemHref={"/jujitsu-lexique"} itemName="Lexique" itemIsActive={router.pathname=="/jujitsu-lexique"}/></li>
                        </ul>
                    </li> 
                    <div className={s.divMBSeparator} />
                    
                    <li>
                        <button type="button" onClick={buttonSMMenuClubHandleClick} className={s.menuBurgerItemTop}>
                            <span className={s.menuBurgerItemTitreAvecSSmenu}>Le Club</span>
                            <svg className={`${router.pathname=="/club-presentation" || router.pathname=="/club-horairestarifs" || SMMenuClubActive ? "rotate-180" : "rotate-0"} ${"w-6 h-6 absolute right-2 transition-transform origin-center duration-500 ease-out"}`} 
                                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" ></path></svg>
                        </button>
                        <ul className={`${router.pathname=="/club-presentation" || router.pathname=="/club-horairestarifs" || SMMenuClubActive ? "max-h-20" : "max-h-0"} flex flex-col overflow-hidden transition-all origin-top duration-500 ease-out`}>
                            <li> <OJMenuBurgerItemSmenu itemHref={"/club-presentation"} itemName="Présentation" itemIsActive={router.pathname=="/club-presentation"}/></li>
                            <li> <OJMenuBurgerItemSmenu itemHref={"/club-horairestarifs"} itemName="Horaires & Tarifs" itemIsActive={router.pathname=="/club-horairestarifs"}/></li>
                        </ul>
                    </li> 
                    <div className={s.divMBSeparator} />
                    <li> <OJMenuBurgerItemTop itemHref={"/contact"} itemName="Contact" itemIsActive={router.pathname=="/contact"}/></li>
                    <div className={s.divMBSeparator} />
                    <li> <OJMenuBurgerItemTop itemHref={"/liens"} itemName="Liens" itemIsActive={router.pathname=="/liens"}/></li>
                </ul>
            </div>
        </div>

        {/* MENU version ORDI */}
        <ul className="hidden md:flex flex-row flex-wrap place-content-end lg:space-x-1 xl:space-x-2">
            <li> <OJMenuItemTop itemHref={"/"} itemName="News" itemIsActive={router.pathname=="/"} /> </li>
            {/* <li> <Link href="/events"><a className={s.menuItem}>Evènements</a></Link></li>
            <li> <Link href="/media"  ><a className={s.menuItem}>Média</a></Link></li>*/}            
            <li>                 
                <OJMenuItemTopDropdown itemHref={"/jujitsu-histoire"} itemName="le Ju-Jitsu" 
                        itemIsActive={router.pathname=="/jujitsu-histoire" || router.pathname=="/jujitsu-codemoral" || router.pathname=="/jujitsu-grades" || router.pathname=="/jujitsu-lexique"}
                        setSMenuActiveCall={SMenuJujitsuHandleHover}>
                    <ul className={`${SMenuJujitsuActive ? "flex" : "hidden"} ${s.menuDivSMenu}`}>
                        <li> <OJMenuItemSmenu itemHref={"/jujitsu-histoire"} itemName="Histoire" itemIsActive={router.pathname=="/jujitsu-histoire"}/></li>
                        <li> <OJMenuItemSmenu itemHref={"/jujitsu-codemoral"} itemName="Code moral" itemIsActive={router.pathname=="/jujitsu-codemoral"}/></li>                       
                        <li> <OJMenuItemSmenu itemHref={"/jujitsu-grades"} itemName="les Grades" itemIsActive={router.pathname=="/jujitsu-grades"}/></li>                       
                        <li> <OJMenuItemSmenu itemHref={"/jujitsu-lexique"} itemName="Lexique" itemIsActive={router.pathname=="/jujitsu-lexique"}/></li>                       
                    </ul>
                </OJMenuItemTopDropdown>                
            </li> 
            <li>                 
                <OJMenuItemTopDropdown itemHref={"/club-horairestarifs"} itemName="le Club" itemIsActive={router.pathname=="/club-presentation" || router.pathname=="/club-horairestarifs"}
                        setSMenuActiveCall={SMenuClubHandleHover}>
                    <ul className={`${SMenuClubActive ? "flex" : "hidden"} ${s.menuDivSMenu}`}>
                        <li> <OJMenuItemSmenu itemHref={"/club-presentation"} itemName="Présentation" itemIsActive={router.pathname=="/club-presentation"}/></li>
                        <li> <OJMenuItemSmenu itemHref={"/club-horairestarifs"} itemName="Horaires & Tarifs" itemIsActive={router.pathname=="/club-horairestarifs"}/></li>                       
                    </ul>
                </OJMenuItemTopDropdown>                
            </li> 
            <li><OJMenuItemTop itemHref={"/contact"} itemName="Contact" itemIsActive={router.pathname=="/contact"}/></li>
            <li><OJMenuItemTop itemHref={"/liens"} itemName="Liens" itemIsActive={router.pathname=="/liens"}/></li>
        </ul>
    </nav>
)}
