// Nom du Site Front
export const SITE_NAME_OJ   = "Open Jujitsu";
export const WEBSITE_URL    =  process.env.NEXT_PUBLIC_WEBSITE_URL; // "https://www.openjujitsu.fr"; // "http://localhost:3000"; //

// hCaptcha (1/2) - Site
export const HCAPTCHA_PUBLICSITE_KEY    = "882030c8-bf26-4040-93e9-f08eef273529";

// Nom du Site Back
export const API_URL_BACKEND            = process.env.API_URL_BACKEND; //"https://localhost"; // "https://ojadmin.openjujitsu.fr"; //

// Sentry DNS for using it
export const SENTRY_DSN     = "https://4b3807d01bd741ec9c89571877526694@o1218031.ingest.sentry.io/6372224";
export const SENTRY_ENV     = "production";